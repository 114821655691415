import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'underscorePipe'
})
export class UnderscorePipe implements PipeTransform {

  transform(value: string): string {
    let newVal = value.replace(/_/g, ' ');
    return newVal;
  }

}