import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    title = 'Darwin';
    adminObj: any;
    userObj: any;
    public self;
    timeoutID: any;
    constructor(private router: Router
      ) {
      this.setup();
    }
    setup() {
      const self = this;
      document.addEventListener('mousemove', function () {
        self.resetTimer();

      });
      document.addEventListener('mousedown', function () {
        self.resetTimer();
      });
      document.addEventListener('keypress', function () {
        self.resetTimer();
      });
      document.addEventListener('DOMMouseScroll', function () {
        self.resetTimer();
      });
      document.addEventListener('mousewheel', function () {
        self.resetTimer();
      });
      document.addEventListener('touchmove', function () {
        self.resetTimer();
      });
      this.startTimer();
    }
    resetTimer() {
      window.clearTimeout(this.timeoutID);

      this.startTimer();
    }

    goInactive() {

      localStorage.clear();
      window.location.reload();

    }
    startTimer() {
      this.timeoutID = window.setTimeout(this.goInactive, 600000);
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}
