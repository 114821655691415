import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-premium-details',
  templateUrl: './premium-details.component.html',
  styleUrls: ['./premium-details.component.scss']
})
export class PremiumDetailsComponent implements OnInit {
  @Output() premiumData: EventEmitter<{ duration: number, unit: string }> = new EventEmitter();
  premiumDataForm: FormGroup;
  setSelectedUnit = "Days";
  durationUnits = ['Days', 'Months', 'Years']
  duration: number;
  custom: boolean = false;
  selectedOption: string;

  constructor(private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.premiumDataForm = new FormGroup({
      duration: new FormControl("", [Validators.required]),
      unit: new FormControl("", [Validators.required])
    });
  }

  selectedUnitClick(val) {
    this.setSelectedUnit = val;
    this.emitPremiumData();
  }

  emitPremiumData() {
    const duration = this.duration;
    const unit = this.setSelectedUnit;
    this.premiumData.emit({ duration, unit });
  }

  selectedData(val) {
    if (val === 'custom') {
      this.selectedOption = "Other"
      this.custom = true
    }
    if (val === '12') {
      this.custom = false;
      this.selectedOption = "12 M"
      this.duration = 12;
      this.setSelectedUnit = "Months"
      this.emitPremiumData();
    }
    if (val === '1') {
      this.custom = false;
      this.selectedOption = "1 M"
      this.duration = 1;
      this.setSelectedUnit = "Months"
      this.emitPremiumData();
    }
  }

  onDurationChange(val) {
    this.duration = val;
    this.emitPremiumData();
  }
}
