import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'usergroups',
    loadChildren: () => import('../../user-groups/user-groups.module').then(m => m.UserGroupsModule)
  },
  {
    path: 'machine',
    loadChildren: () => import('../../machine/machine.module').then(m => m.MachineModule)
  },
  {
    path: 'user',
    loadChildren: () => import('../../user/user.module').then(m => m.UserModule)
  }, 

  {
    path: 'materials',
    loadChildren: () => import('../../materials/materials.module').then(m => m.MaterialsModule)
  }, 

  {
    path: 'electricity',
    loadChildren: () => import('../../electricity/electricity.module').then(m => m.ElectricityModule)
  }, 

  {
    path: 'powder',
    loadChildren: () => import('../../powder/powder-energy.module').then(m => m.PowderModule)
  }, 

  {
    path: 'carbon',
    loadChildren: () => import('../../carbon/carbon.module').then(m => m.CarbonModule)
  }, 

  {
    path: 'carbon-price',
    loadChildren: () => import('../../carbon-price/carbon-price.module').then(m => m.CarbonPriceModule)
  }, 

  {
    path: 'hotspot-analysis',
    loadChildren: () => import('../../hotspot-analysis/hotspot-analysis.module').then(m => m.HotspotAnalysisModule)
  }, 

  {
    path: 'parameters',
    loadChildren: () => import('../../parameters/parameters.module').then(m => m.ParametersModule)
  },

  {
    path: 'filters',
    loadChildren: () => import('../../filters/filters.module').then(m => m.FiltersModule)
  },

  {
    path: 'build-plates',
    loadChildren: () => import('../../build-plates/build-plates.module').then(m => m.BuildPlatesModule)
  },

  {
    path: 'recoater-blades',
    loadChildren: () => import('../../recoater-blades/recoater-blades.module').then(m => m.RecoaterBladesModule)
  },

  {
    path: 'admin',
    loadChildren: () => import('./../../admin-users/admin-users.module').then(m => m.AdminUsersModule)
  },

  {
    path: 'blog',
    loadChildren: () => import('../../blog/blog.module').then(m => m.BlogModule)
  }, 
  {
    path: 'reports',
    loadChildren: () => import('../../reports/report.module').then(m => m.ReportModule)
  }, 
  {
    path: 'access',
    loadChildren: () => import('../../Premium/premium.module').then(m => m.AccessModule)
  }, 
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  }, 
];
