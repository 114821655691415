import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "./../../../environments/environment";

interface ResponseData {
  code: any;
  message: string;
  data: Object;
}

@Injectable()
export class ReportService {
  constructor(private http: HttpClient) {}

  getAllReports(pageSize, pageNo, token , searchValue , isPaging = true ) {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("authorization", `${token}`);
    return this.http.get(
      environment.serviceUrlHttp +
        `/report?pageNo=${pageNo}&pageSize=${pageSize}&isAdmin=true&searchValue=${searchValue}&isPaging=${isPaging}`,
      { headers: headers }
    );
  }
  getReportById(report_id , token) {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("authorization", `${token}`);
    return this.http.get(
      environment.serviceUrlHttp +
        `/report/${report_id}`,
      { headers: headers }
    );
  }
}
