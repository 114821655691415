import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
        {
                path: '/dashboard/dashboard2', title: 'Dashboard', icon: 'ft-home',
                 class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/usergroups/list', title: 'User Groups', icon: 'ft-users',
                class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/user/list', title: 'Users', icon: 'ft-user',
                 class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/machine/list', title: 'Machines', icon: 'ft-settings',
                class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/materials/list', title: 'Materials', icon: 'ft-layers',
                 class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/electricity/list', title: 'Electricity', icon: 'ft-layers',
                 class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        // {
        //         path: '/powder/list', title: 'Powder Atomization', icon: 'ft-layers',
        //          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        // },
        {
                path: '/carbon/list', title: 'Carbon Attributes', icon: 'ft-layers',
                 class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/carbon-price/list', title: 'Carbon Price', icon: 'ft-layers',
                 class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/hotspot-analysis/list', title: 'Hotspot Analysis', icon: 'ft-layers',
                 class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/parameters/list', title: 'Parameters', icon: 'ft-life-buoy',
                 class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/filters/list', title: 'Filters', icon: 'ft-filter',
                class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/reports/list', title: 'Reports', icon: 'ft-file',
                class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        // {
        //         path: '/access/list', title: 'Access', icon: 'ft-bell',
        //         class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        // },
        {
                path: '/build-plates/list', title: 'Build Plates', icon: 'ft-chrome',
                class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/recoater-blades/list', title: 'Recoater Blades', icon: 'ft-target',
                class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        },
        {
                path: '/blog/list', title: 'Blog', icon: 'ft-book',
                 class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], display: true
        }

];
